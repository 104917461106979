import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, TextField, FormControl, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";

import { NO_CROP } from "../../../../../evidence/actions/shared/misc/action.helpers";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../../shared/components/form/FormSelect/types";
import { COLOR_PRIMARY } from "../../../../../theme";
import { makeStyles } from "@mui/styles";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import { InternalCropTo } from "../../../../../generated/api/satellite";

type Props = {
  cropsOptions: SelectObject<InternalCropTo>[];
  setEdit: (val: boolean) => void;
  value?: SelectObject<InternalCropTo>;
  handleChange: (val?: SelectObject<InternalCropTo>) => void;
};

const CropsDropdown = ({
  cropsOptions,
  setEdit,
  value,
  handleChange,
}: Props) => {
  const classes = useStyles();
  const intl = useTypedIntl();
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [clearButtonClicked, setClearButtonClicked] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleInputChange = (_: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue === "") {
      setClearButtonClicked(true);
    } else {
      setClearButtonClicked(false);
    }
  };

  const handleChangeValue = (
    _: any,
    newValue: SelectObject<InternalCropTo> | null,
  ) => {
    if (newValue && (newValue.key !== NO_CROP.id || !clearButtonClicked)) {
      handleChange(newValue);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <CustomAutocomplete
        options={cropsOptions}
        open={isOpen}
        getOptionLabel={(option: SelectObject<InternalCropTo>) =>
          option?.key === NO_CROP.id
            ? ""
            : cropsOptions.find((item) => item.key === option?.key)?.value ||
              SELECT_EMPTY_OBJ.value
        }
        value={value || SELECT_EMPTY_OBJ}
        onChange={handleChangeValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(
          option: SelectObject<InternalCropTo>,
          value: SelectObject<InternalCropTo>,
        ) => option.key === value.key}
        onClose={() => {
          setEdit(false);
          setIsOpen(false);
        }}
        renderOption={(
          props,
          option: SelectObject<InternalCropTo>,
          { selected },
        ) => {
          return (
            <CustomMenuItem
              {...props}
              key={option.key}
              style={
                selected
                  ? {
                      color: "black",
                      background: "#F3F3F3",
                      backgroundColor: "#F3F3F3",
                    }
                  : undefined
              }
            >
              {option.key === NO_CROP.id ? (
                <span>
                  <FormattedMessage id="common.noCrop" />
                </span>
              ) : (
                option.value
              )}
            </CustomMenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            onFocus={() => setInputValue("")}
            placeholder={
              value?.key === NO_CROP.id
                ? intl.formatMessage({ id: "common.noCrop" })
                : cropsOptions.find((item) => item.key === value?.key)?.value ||
                  SELECT_EMPTY_OBJ.value
            }
          />
        )}
      />
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const CustomAutocomplete = styled(Autocomplete)(() => ({
  backgroundColor: "transparent",
  borderRadius: 8,
  "& .MuiAutocomplete-inputRoot .MuiInputBase-input": {
    padding: 0,
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: COLOR_PRIMARY.cleverfarm.main,
  },
  "& .Mui-focused": {
    color: COLOR_PRIMARY.cleverfarm.main,
    fontWeight: 500,
    borderColor: COLOR_PRIMARY.cleverfarm.main,
    borderRadius: 8,
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: "8px 16px",
  color: theme.palette.grey[500],
  fontSize: 12,
  fontWeight: 500,
  "&.Mui-selected": {
    color: theme.palette.common.black,
    backgroundColor: "#f5f5f5",
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#f5f5f5",
    outline: "none",
  },
  "&.Mui-selected:focus": {
    backgroundColor: "#f5f5f5",
    outline: "none",
  },
}));

export { CropsDropdown };
