import { useEffect } from "react";
import { useToggle } from "../../../../../shared/hooks/useToggle";

const useDelayedLoader = (isPending: boolean, delay: number) => {
  const state = useToggle(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (isPending) {
      timeout = setTimeout(() => {
        state.setOn();
      }, delay);
    } else {
      clearTimeout(timeout);
      state.setOff();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isPending, delay]);

  return {
    isDelayed: state.on,
  };
};

export { useDelayedLoader };
