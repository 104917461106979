import { initialDateFilter } from "../reducer/telematicsTabs.reducer";

import { getShortDateString } from "../../shared/misc/timeHelpers";

import { TelematicsState } from "../../reducers/telematics.reducer.types";

const selectDateRange = (state: TelematicsState) =>
  state.ui.telematicsTabs.dateFilter ?? initialDateFilter;

const selectShortDateRange = (state: TelematicsState) => {
  const { dateFrom, dateTo } = state.ui.telematicsTabs.dateFilter;
  return {
    dateFrom: getShortDateString(dateFrom),
    dateTo: getShortDateString(dateTo),
  };
};

export { selectDateRange, selectShortDateRange };
